import Api from '@/services/API';
import standard from '@/config/service/standard';


/**
 * @typedef {Rest}
 */
export default class Rest extends Api {
  /**
   * @type {String}
   */
  static resource = ''

  /**
   * @type {String}
   */
  id = 'id'

  /**
   * @param {String} resource
   * @param {Object} options
   * @param {Object} http
   */
  constructor(resource, options = {}, http = null) {
    super(Rest.normalize(Rest.base, resource), options, http);
  }

  /**
   * @return {this}
   */
  static build() {
    return new this(this.resource);
  }

  /**
   * @param {Object} record
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
  create(record, id = null, overRoute, config = {}) {
    standard.defaults.responseType = 'json';
    if (!id) {
      return this.post(`${overRoute ? overRoute : ''}`, record, config);
    } else {
      return this.post(`/${id}`, record, config);
    }
  }

  /**
   * @param {Object} record
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
  patch(record, id = null, config = {}) {
    standard.defaults.responseType = 'json';
    if (!id) {
      return this.patch('', record, config);
    } else {
      return this.patch(`/${id}`, record, config);
    }
  }

  /**
   * @param {Object} record
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
  blob(record, config = {}) {
    standard.defaults.responseType = 'blob';
    return this.post('', record, config);
  }

  /**
   * @param {Object} parameters
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
  blobSearch(parameters = {}, config = {}) {
    standard.defaults.responseType = 'blob';
    let url = '';
    if (typeof parameters['url'] != 'undefined') {
      url = parameters['url'];
      delete parameters['url'];
    }

    const queryString = Object.keys(parameters).map(key => {

      if (Array.isArray(parameters[key])) {
        return key + '[]' + '=' + parameters[key].join(`&${key}[]=`)
      } else {
        return key + '=' + parameters[key]
      }

    }).join('&');
    // apply your logic here
    if (Object.keys(parameters).length)
      return this.get(`${url}?${queryString}`, config);

    return this.get(url, config);
  }

  /**
   * @param {Object} record
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
  createId(record, config = {}) {
    return this.post(`/${this.getId(record)}`, record, config);
  }

  /**
   * @param {String|Object} record
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
  read(record, config = {}) {
    standard.defaults.responseType = 'json';
    return this.get(`/${this.getId(record)}`, config);
  }

  /**
   * @param {Object} record
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
  update(record, id = null, config = {}) {
    standard.defaults.responseType = 'json';
    if (!id) {
      return this.put(`/${this.getId(record)}`, record, config);
    } else {
      return this.put(`/${id}`, record, config);
    }
  }

  /**
   * @param {Object} record
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
  destroy(record, config = {}) {
    standard.defaults.responseType = 'json';
    return this.delete(`/${this.getId(record)}`, config);
  }

  /**
   * @param {Object} parameters
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
  search(parameters = {}, config = {}) {
    standard.defaults.responseType = 'json';
    let url = '';
    if (typeof parameters['url'] != 'undefined') {
      url = parameters['url'];
      delete parameters['url'];
    }

    const queryString = Object.keys(parameters).map(key => {

      if (Array.isArray(parameters[key])) {
        return key + '[]' + '=' + parameters[key].join(`&${key}[]=`)
      } else {
        return key + '=' + parameters[key]
      }

    }).join('&');
    // apply your logic here
    if (Object.keys(parameters).length)
      return this.get(`${url}?${queryString}`, config);

    return this.get(url, config);
  }

  /**
   * @param {String|Object} record
   * @returns {String}
   */
  getId(record) {
    standard.defaults.responseType = 'json';
    if (typeof record === 'object') {
      return record[this.id]
    }
    return String(record);
  }
}