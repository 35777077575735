<template>
  <div class="container-menu">
    <template v-if="!getMenuMobilePermissions.length">
      <div v-for="(n, index) in 8" class="item load" :key="index">
        <div class="menu-item-loading">
          <div class="icone"></div>
        </div>
      </div>
    </template>
    <template v-else>
      <template v-for="(menu, i) in getMenuMobilePermissions">
        <item v-if="
              menu.name === 'Commissions' && ambassadorEnabled && ambassadorAcceptedTerms
            " :name="$t('toggle_side.' + menu.title)" :route="`${$t('route_name.' + menu.name)}`" :icon="menu.name.toLowerCase()" :key="i" />
        <item v-if="
              menu.name === 'Commissions' && ambassadorEnabled && !ambassadorAcceptedTerms
            " :name="$t('toggle_side.' + menu.title)" :route="`${$t('route_name.' + menu.name)}`" :icon="menu.name.toLowerCase()" :key="i" />
        <item v-if="
          menu.name !== 'Commissions' &&
          (!$store.getters.setPanel ||
          ($store.getters.setPanel))
        " :name="$t('toggle_side.' + menu.title)" :route="`${$t('route_name.' + menu.name)}`" :icon="menu.name.toLowerCase()" :key="i" />

      </template>

      <!-- <item route="Vitrine" name="Vitrine" icon="checkout-link" /> -->

      <item route="MyAccount" name="Minha Conta" icon="user-config" />
      <item route="Settings" name="Configurações" icon="company-config" />
      <div class="Menu-item-logout" @click.prevent="logout">
        <a href="#">
          <img src="@/assets/img/icons/sign-out.svg" />
          {{ $t("side.text_1964") }}
        </a>
      </div>
    </template>
  </div>
</template>
<script>
import Item from "./Item";
import Vue from "vue";
import { mapGetters } from "vuex";
import Utils from "@/mixins/utils";

export default {
  name: "ToggleMenu",
  mixins: [Utils],
  components: {
    Item,
  },
  computed: {
    ...mapGetters(["getMenuMobilePermissions"]),
  },
  methods: {
    eventLoginClub() {
      Vue.swal({
        title: this.$t("side.text_1973"),
        text: this.$t("side.text_1971"),
        type: "info",
        showCancelButton: true,
        confirmButtonText: "Entrar no Voomp Play",
        cancelButtonText: this.$t("side.text_1975"),
        customClass: "sweet-container",
        confirmButtonClass: "button button-black mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("clubLogin").catch(function () { });
        }
        if (result.dismiss == "cancel") {
          this.$router.push("/produtos");
        }
      });
    },
    logout() {
      this.$store.dispatch("logoutRequest").catch(function () { });
    },
    openHelpLink() {
      window.open('https://faq.voompcreators.com.br', '_blank');
    }
  },
};
</script>

<style scoped>
.container-menu {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px 10px;
  width: 100%;
  max-height: 100vh;
  padding-bottom: 150px;
  overflow: auto;
}

.Menu-item,
.Menu-item-logout {
  height: 70px;
}

.Menu-item a {
  color: #333 !important;
}

.Menu-item-logout {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 2px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

.Menu-item-logout a {
  width: 100%;
  padding: 20px;
  display: flex;
  text-decoration: none;
  color: #333;
  font-size: 13px;
  font-weight: 600;
}

.Menu-item-logout img {
  margin-right: 20px;
  width: 17px;
}

.Menu-item-logout:hover {
  background-color: #ff0c37;
  cursor: pointer;
}

.Menu-item-logout:hover a {
  color: #fff;
}

.Menu-item-logout:hover img {
  filter: brightness(50) invert(0);
}

.load .menu-item-loading img {
  filter: invert(50%);
}

.menu-item-loading {
  display: flex;
  align-items: center;
}

.menu-item-loading .icone {
  width: 100%;
  height: 80px;
  padding: 14px;
  background: #f7f7f7;
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* Adicionando a animação */
  animation: background-opacity 2s infinite alternate;
}

@keyframes background-opacity {
  0% {
    opacity: 0.5;
  }

  100% {
    opacity: 1.5;
  }
}
</style>
