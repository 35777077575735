import Cookies from 'js-cookie';
import AuthService from '@/services/resources/AuthService';
import LogoutService from '@/services/resources/LogoutService';
import AdminLoginService from '@/services/resources/AdminLoginService';
import router from '@/router/index'

export default {

    registerLogin(context, payload){
        let access_token = 'Bearer ' + payload.access_token;
        Cookies.set('access_token', access_token, {
            expires: 365
        });

        context.commit('authSuccess', access_token);
        context.dispatch('userRequest');
        context.dispatch('clubVerification');
        context.dispatch('emailVerification');

    },

    //fazer login do OAuth 2
    loginRequest(context, payload) {
        let service = AuthService.build();
        return new Promise((resolve, reject) => {

            service.login(payload.username, payload.password, payload.captcha)
                .then((resp) => {
                    let access_token = 'Bearer ' + resp.access_token;
                    Cookies.set('access_token', access_token, {
                        expires: 365
                    });

                    context.commit('authSuccess', access_token);
                    context.dispatch('userRequest');
                    context.dispatch('clubVerification');
                    context.dispatch('emailVerification');





                    resolve(access_token);
                })
                .catch((err) => {
                    context.commit('authError', err);
                    Cookies.remove('access_token');
                    reject(err);
                })
        })

    },
    authRequestWhitUser(context, payload) {

        return new Promise((resolve, reject) => {

            let service = AdminLoginService.build();

            let data = {
                user_id: payload.user_id,
            };
            service.create(data)
                .then((resp) => {
                    let access_token = 'Bearer ' + resp.access_token;
                    Cookies.set('access_token', access_token, {
                        expires: 365
                    });

                    context.commit('authSuccess', access_token);
                    context.dispatch('adminUserRequest');
                    resolve(access_token);
                })
                .catch((err) => {

                    reject(err);
                })
        })

    },

    logoutRequest: ({
        commit,
        dispatch
    }) => {
        // commit('authLogout');
        commit('userLogout');
        // Obter o primeiro segmento do pathname após o host
        const pathSegments = window.location.pathname.split('/').filter(segment => segment);
        const firstPathSegment = pathSegments.length > 0 ? pathSegments[0] : '';
        if(firstPathSegment !== 'admin-login') {
            window.$crisp.push(['do', 'chat:hide']);
        }
    }
}