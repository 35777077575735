export const DateUnit = {
    SECOND: 'second',
    MINUTE: 'minute',
    HOUR: 'hour',
    DAY: 'day',
    MONTH: 'month',
    YEAR: 'year'
};

export class DateUtil {

/**
 * @param {Date} firstDate 
 * @param {Date} secondDate
 * @param {DateUnit} unit
 * @returns {Number} The difference between the two dates in days
 */
  static diff(firstDate, secondDate, unit) {
    const startDate = DateUtil.toDate(firstDate);
    const endDate = DateUtil.toDate(secondDate);
    if (!startDate || !endDate) {
      return null;
    }
    const diffTime = Math.abs(endDate - startDate);
    switch (unit) {
      case DateUnit.SECOND:
        return Math.ceil(diffTime / 1000);
      case DateUnit.MINUTE:
        return Math.ceil(diffTime / (1000 * 60));
      case DateUnit.HOUR:
        return Math.ceil(diffTime / (1000 * 60 * 60));
      case DateUnit.DAY:
        return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      case DateUnit.MONTH:
        return Math.ceil(diffTime / (1000 * 60 * 60 * 24 * 30));
      case DateUnit.YEAR:
        return Math.ceil(diffTime / (1000 * 60 * 60 * 24 * 365));
      default:
        return null;
    }
  }

  static toDate(date) {
    try {
        return new Date(date);
    } catch (error) {
        return null;
    }
  }
  /**
   * @param {Date} date 
   * @param {String} format 
   * @returns String formatted date
   */
  static format(date, format) {
    if (!date) {
      return '';
    }

    const dateObj = DateUtil.toDate(date);
    const padZero = (value, length = 2) => String(value).padStart(length, '0');
    const map = {
      yyyy: dateObj.getFullYear(),
      yy: dateObj.getFullYear().toString().slice(-2),
      MM: padZero(dateObj.getMonth() + 1),
      dd: padZero(dateObj.getDate()),
      HH: padZero(dateObj.getHours()),
      mm: padZero(dateObj.getMinutes()),
      ss: padZero(dateObj.getSeconds()),
      SSS: padZero(dateObj.getMilliseconds(), 3)
    };
  
    return format.replace(/yyyy|yy|MM|dd|HH|mm|ss|SSS/g, (matched) => map[matched]);
  }
}