import SubUsersPermissionsService from "@/services/resources/SubUsersPermissionsService";
const serviceSubUsersPermissions = SubUsersPermissionsService.build();

const state = {
    menuPermissions: [],
    submenuSalesPermissions: [],
    menuMobilePermissions: [],
    permissions: {},
    allPermissions: [],
    allPermissionsNames: [],
    loadingMenu: true,
}

const mutations = {
    SET_LOADING_MENU(state, loading) {
        state.loadingMenu = loading;
    },
    SET_PERMISSIONS(state, permissions) {
        state.permissions = permissions;
    },
    SET_VERIFY_PERMISSIONS(state, verifyPermissions) {
        state.verifyPermissions = verifyPermissions;
    },
    SET_ALL_PERMISSIONS(state, allPermissions) {
        localStorage.setItem('allPermissions', JSON.stringify(allPermissions));
        state.allPermissions = allPermissions;
    },
    SET_ALL_PERMISSIONS_NAMES(state, allPermissionsNames) {
        localStorage.setItem('allPermissionsNames', JSON.stringify(allPermissionsNames));
        state.allPermissionsNames = allPermissionsNames;
    },
    SET_MENU_PERMISSIONS(state, menuPermissions) {
        state.menuPermissions = menuPermissions;
    },
    SET_SUBMENU_SALES_PERMISSIONS(state, submenuSalesPermissions) {
        state.submenuSalesPermissions = submenuSalesPermissions;
    },
    SET_MENU_MOBILE_PERMISSIONS(state, menuMobilePermissions) {
        state.menuMobilePermissions = menuMobilePermissions;
    },
    RESET_PERMISSIONS(state) {
        state.menuPermissions = [];
        state.submenuSalesPermissions = [];
        state.menuMobilePermissions = [];
        state.allPermissions = [];
        state.allPermissionsNames = [];
        state.permissions = {};
    },
}

const getters = {
    getLoadingMenu: state => state.loadingMenu,
    getPermissions: state => state.permissions,
    getAllPermissions: state => state.allPermissions,
    getAllPermissionsNames: state => state.allPermissionsNames,
    viewMoreMenu: state => state.permissions.length > 5,
    getMenuPermissions: state => state.menuPermissions,
    getSubmenuSalesPermissions: state => state.submenuSalesPermissions,
    getMenuMobilePermissions: state => state.menuMobilePermissions,
    getVerifyPermissions: state => state.verifyPermissions,
    permissions: state => state.permissions,
}

const actions = {
    async fetchPermissions ({ state, commit, dispatch }, idSubUserGroup = null)  {
        return new Promise((resolve, reject) => {
          commit("SET_LOADING_MENU", true);
          let params = idSubUserGroup ? idSubUserGroup : "";
          serviceSubUsersPermissions
            .read(params)
            .then((response) => {
              commit("SET_ALL_PERMISSIONS", response);
              commit("SET_PERMISSIONS", response);
              resolve(response);
              commit("SET_LOADING_MENU", false);
            })
            .catch((err) => {
              reject(err);
            });
        });
    },
    async actionPermissions({ dispatch, commit, getters }, idSubUserGroup = null) {
        commit('RESET_PERMISSIONS');
    
        let dadosAllPermissions = null;
        const allPermissions = JSON.parse(localStorage.getItem('allPermissions'));
        const allPermissionsNames = JSON.parse(localStorage.getItem('allPermissionsNames'));
        
        if (Array.isArray(allPermissions) && allPermissions.length) {
            dadosAllPermissions = allPermissions;
        } else {
            try {
                await dispatch("fetchPermissions", idSubUserGroup);
                dadosAllPermissions = await getters.getAllPermissions;
            } catch (error) {
                console.error('permissions.actionPermissions => Erro ao obter permissões:', error);
            }
        }
    
        if (dadosAllPermissions && dadosAllPermissions.length) {
            let dadosAllPermissionNames = [];
            if(Array.isArray(allPermissionsNames)) {
                dadosAllPermissionNames = allPermissionsNames;  
            } else {
                dadosAllPermissions.forEach(item => {
                    if (item.types && item.types.edit === true) {
                        dadosAllPermissionNames.push(item.name);
                    } else if (item.types && item.types.view === true) {
                        dadosAllPermissionNames.push(item.name);
                    }
                });  
            }
            
            const submenuSalesPermissions = dadosAllPermissions.filter(item => {
                return item.types && item.types.edit === true && ['Sales', 'Commissions', 'Contracts'].includes(item.name);
            });
    
            const menuPermissions = dadosAllPermissions.filter(item => {
                return item.types && item.types.edit === true && !['Commissions', 'Contracts'].includes(item.name);
            });
    
            const menuMobilePermissions = dadosAllPermissions.filter(item => {
                return item.types && item.types.edit === true;
            });
    
            const verifyPermissions = dadosAllPermissions.reduce((obj, item) => {
                obj[item.name] = item.types && (item.types.view === true || item.types.edit === true);
                return obj;
            }, {});
    
            const permissions = [...new Set(menuPermissions.flatMap(user => 
                user.permissions.map(permission => permission.name)
            ))];
    
            commit('SET_VERIFY_PERMISSIONS', verifyPermissions);
            commit('SET_ALL_PERMISSIONS', dadosAllPermissions);
            commit('SET_ALL_PERMISSIONS_NAMES', dadosAllPermissionNames);
            commit('SET_PERMISSIONS', permissions);
            commit('SET_MENU_PERMISSIONS', menuPermissions);
            commit('SET_SUBMENU_SALES_PERMISSIONS', submenuSalesPermissions);
            commit('SET_MENU_MOBILE_PERMISSIONS', menuMobilePermissions);
        }
    }    
}

export {
    state,
    getters,
    actions,
    mutations,
}