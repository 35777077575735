<template>
  <div
    class="item"
    v-if="
      (painels.length > 1 && !isMobile) ||
      (painels.length > 1 && isMobile && $route.name === 'Dashboard')
    "
  >
    <button
      class="view"
      :class="{ 'mt-5': isMobile }"
      data-anima="top"
      v-if="painels.length > 1 && $router.history.current.name !== 'MyAccount' && $router.history.current.name !== 'Settings'"
      @click="subMenu03"
    >
      <span v-if="header">Vendo como {{ getName() }}</span>
      <svg
        :class="{ 'ml-2': header }"
        width="12"
        height="7"
        viewBox="0 0 12 7"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.53033 0.46967C1.23744 0.176777 0.762563 0.176777 0.46967 0.46967C0.176777 0.762563 0.176777 1.23744 0.46967 1.53033L1.53033 0.46967ZM6 6L5.46967 6.53033C5.76256 6.82322 6.23744 6.82322 6.53033 6.53033L6 6ZM11.5303 1.53033C11.8232 1.23744 11.8232 0.762563 11.5303 0.46967C11.2374 0.176777 10.7626 0.176777 10.4697 0.46967L11.5303 1.53033ZM0.46967 1.53033L5.46967 6.53033L6.53033 5.46967L1.53033 0.46967L0.46967 1.53033ZM6.53033 6.53033L11.5303 1.53033L10.4697 0.46967L5.46967 5.46967L6.53033 6.53033Z"
          fill="#333"
        />
      </svg>
    </button>

    <ul
      class="sub-menu sub-menu3"
      :class="{ 'sub-menu-mobile': isMobile }"
      id="sub_03"
      v-if="painels.length > 1"
      v-show="sub_menu3"
    >
      <p class="title">Suas contas</p>
      <li
        @click="sub_menu3 = false"
        v-for="(painel, index) in painels"
        :key="painel + index"
      >
        <div @click="setPainel(painel)" class="grid-list">
          <div
            class="image"
            :class="{
              'active-border':
                painel.id == $store.getters.setPanel ||
                ($store.getters.setPanel === null && index === 0),
            }"
          >
            <img v-if="painel.image" :src="painel.image" alt="image" />
          </div>
          <div class="text">
            <p
              class="name"
              :class="{
                active:
                  painel.id == $store.getters.setPanel ||
                  ($store.getters.setPanel === null && index === 0),
              }"
            >
              {{ painel.name }}
            </p>
            <p
              class="mail"
              :class="{
                active:
                  painel.id == $store.getters.setPanel ||
                  ($store.getters.setPanel === null && index === 0),
              }"
            >
              {{ painel.email }}
            </p>
          </div>
          <div class="active-svg">
            <svg
              v-if="
                painel.id == $store.getters.setPanel ||
                ($store.getters.setPanel === null && index === 0)
              "
              width="13"
              height="10"
              viewBox="0 0 13 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.545 1.51522C12.8296 1.21421 12.8162 0.739523 12.5152 0.454976C12.2142 0.17043 11.7395 0.183774 11.455 0.484782L12.545 1.51522ZM4.4375 9L3.89248 9.51522C4.03415 9.66508 4.23127 9.75 4.4375 9.75C4.64373 9.75 4.84085 9.66508 4.98252 9.51522L4.4375 9ZM1.54502 4.84842C1.26048 4.54741 0.785791 4.53407 0.484782 4.81861C0.183774 5.10316 0.17043 5.57785 0.454976 5.87885L1.54502 4.84842ZM11.455 0.484782L3.89248 8.48478L4.98252 9.51522L12.545 1.51522L11.455 0.484782ZM4.98252 8.48478L1.54502 4.84842L0.454976 5.87885L3.89248 9.51522L4.98252 8.48478Z"
                fill="#002363"
              />
            </svg>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import Cookies from "js-cookie";

export default {
  props: {
    header: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sub_menu3: false,
      painels: [],
      client: {
        width: 0,
      },
    };
  },
  computed: {
    isMobile() {
      return this.client.width <= 768;
    },
  },
  mounted() {
    this.sub_menu3 = false;
    this.fetchSubUsers();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();

    this.$root.$on("updateListUser", (data) => {
      this.fetchSubUsers();
    });
    this.$root.$on("exitMenu", (data) => {
      this.sub_menu3 = false;
    });
  },
  methods: {
    handleResize() {
      this.client.width = window.innerWidth;
    },
    async setPainel(panel) {
      const isAuthenticated = this.$store.getters.isAuthenticated;
      localStorage.removeItem("allPermissions");
      localStorage.removeItem("allPermissionsNames");
      // Removendo os filtros de vendas do usuario anterior
      window.localStorage.removeItem("sales_filters_aux");
      window.localStorage.removeItem("sales_filters");
      Cookies.remove("setPanelName");
      Cookies.remove("setPanelPermission");

      if (panel.id === 0) {
        this.$store.dispatch("resetPanel");
        await this.$store.dispatch("actionPermissions");
        this.fetchSubUsers();
        this.$parent.$parent.$children.forEach(function(child) {
          if (typeof child.initAll === 'function') {
            child.initAll();
          }
        });
        this.$router.replace(`dashboard`)
        return;
      }
      this.$store.dispatch("setPanel", panel);
      Cookies.set("setPanelName", panel.name, {
        expires: 7,
      });
      let sub_user_role_id = await this.getSubUserRoleId(panel);
      await this.$store.dispatch("actionPermissions", sub_user_role_id);
      let userPermissions = []
      userPermissions = this.$store.getters.getAllPermissionsNames;
      const routes = {
        Dashboard: "dashboard",
        Sales: "vendas",
        Contracts: "contratos",
        Commissions: "comissoes",
        Products: "produtos",
        Notes: "central-de-notas",
        Links: "links",
        Checkouts: "checkouts",
        Reports: "relatorios",
        Statements: "extrato",
        Coupons: "cupom",
        Leads: "gestao-leads",
        Claims: "reclamacoes",
        Integrations: "integracoes",
        Showcase: "vitrine",
        Recruitment: 'recrutamento'
      };
      const requiredPermissions =
        this.$router.history.current.meta.permissionName || null;
      const hasPermission = await userPermissions.includes(requiredPermissions);
      let currentRoute = null;

      if (isAuthenticated && (hasPermission || requiredPermissions === null)) {
        currentRoute = await routes[requiredPermissions];
        console.log("BaseView go to 1",currentRoute,this.$router.history.current.path);
        if(
          '/'+currentRoute === this.$router.history.current.path
          || '/'+currentRoute+'/' === this.$router.history.current.path
          || currentRoute+'/' === this.$router.history.current.path
          || typeof currentRoute === 'undefined'
        ) {
          console.log("BaseView go 0");
          this.$router.go(0);
        } else {
          console.log("BaseView go currentRoute");
          this.$router.push({path: currentRoute});
        }
      } else if (isAuthenticated && !hasPermission) {
        currentRoute = routes[userPermissions[0]];
        console.log("BaseView go to 2",currentRoute);
        this.$router.push({path: currentRoute});
      } else {
        console.log("BaseView back to 1");
        this.$router.go(-1);
      }
      if(currentRoute === 'dashboard') {
        this.$parent.$parent.$children.forEach(function(child) {
          if (typeof child.initAll === 'function') {
            child.initAll();
          }
        });
      }
    },
    getSubUserRoleId(panel) {
      let user = JSON.parse(localStorage.getItem("user"));
      let child_id = user.id;
      let parent_id = panel.id;
      let sub_user_parent = panel.sub_user_parent.filter(item => item.child_user_id == child_id && item.parent_user_id == parent_id);
      return sub_user_parent[0].sub_user_role_id;
    },
    fetchSubUsers() {
      this.$store
        .dispatch("getPanels")
        .then((resp) => {
          const result = resp;
          result.unshift({
            id: 0,
            name: this.$store.getters.getProfile.name,
            email: this.$store.getters.getProfile.email,
            image: this.$store.getters.getProfile.image,
          });
          for (let i = 0; i < result.length; i++) {
            const element = result[i];
            if (this.$store.getters.setPanel == element.id) {
              this.painels = this.changePosition(result, i, 0);
              return;
            }
          }
          this.painels = result;
          this.getName();
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        })
        .finally(async () => {
          // Obter a rota atual
          const currentQuery = await { ...this.$route.query };
          let parent_id = await currentQuery.parent_id ? atob(currentQuery.parent_id) : null;

          // Remover o parâmetro parent_id
          await delete currentQuery.parent_id;

          // Navegar para a mesma rota sem o parâmetro parent_id
          await this.$router.replace({ path: this.$route.path, query: currentQuery });
          
          if(await parent_id) {
            if(await this.painels && this.painels.length > 0) {
              // Atualizar o painel com o novo parent_id
              let panel = await this.painels.filter(item => item.id == parent_id);
              await this.setPainel(panel[0]);
            }
          }
        });
    },
    changePosition(arr, from, to) {
      arr.splice(to, 0, arr.splice(from, 1)[0]);
      return arr;
    },
    subMenu03(e) {
      this.item = e.currentTarget;
      this.top = this.item.offsetTop - 10;
      const submenu = document.querySelector("#sub_03");
      submenu.style = `top: ${this.top}px`;
      this.bottom = this.top + submenu.offsetHeight;
      this.fimSubmenu = submenu.offsetLeft - 20;
      this.sub_menu3 = !this.sub_menu3;
    },
    getName() {
      if (Cookies.get("setPanelName")) {
        return Cookies.get("setPanelName");
      } else {
        return this.$store.getters.getProfile.name;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.sub-menu-mobile {
  top: 47px !important;
}

.item {
  position: relative;
  transition: all 0.5s;
  margin-top: -30px;
  width: 250px;
}
.view {
  color: #333;
  background: #f7f7f7;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  padding: 10px 15px;
  border-radius: 5px;
  border: none;
}

/* Sub menu */
.sub-menu {
  width: 300px;
  box-shadow: 2px 6px 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  background: #fff;
  padding: 25px;
  position: absolute;
  margin-top: 50px;
  // left: 200px;
  /* top: 200px; */
  z-index: 55555 !important;
  animation: showTop 0.3s forwards;
}

.sub-menu li a {
  font-weight: 600;
  color: #333;
  font-size: 14px;
  padding: 10px;
  display: flex;
  cursor: pointer;
}
.sub-menu li a.router-link-exact-active::before {
  left: -15px;
}
.sub-menu li a:hover {
  text-decoration: none;
  color: #000;
}
.sub-menu li a + a {
  margin-top: 20px;
}
.sub-menu li a img {
  margin-right: 25px;
}
.Menu:hover .menu-links a {
  color: #333;
}

.grid-list {
  display: grid;
  grid-template-columns: 55px 3fr 1fr;
  align-items: center;
  margin-top: 10px;
  cursor: pointer;
  z-index: 9999999999999;
  .text {
    .name {
      font-weight: 600;
      font-size: 13px;
    }
    .mail {
      word-break: break-all;
      font-weight: normal;
      font-size: 12px;
      color: #81858e;
    }
  }
  .image {
    width: 35px;
    height: 35px;
    border-radius: 100%;
    border: 1px solid;
    border-color: #e5e5e5;
    background: #f4f4f4;
    img {
      width: 100%;
      object-fit: cover;
      height: 100%;
      border-radius: 100%;
    }
  }
  .active {
    color: #2a63ab !important;
  }
  .active-border {
    border-color: #2a63ab;
  }
}
.title {
  font-weight: normal;
  font-size: 11px;
  color: #81858e;
  margin-bottom: 15px;
}

.active-svg {
  text-align: end;
}

.foto-ususario {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: #ededf0;
  margin-right: 20px;
  -o-object-fit: contain;
  object-fit: contain;
}
</style>
