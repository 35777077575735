import { DateUtil } from '@/utils/DateUtil.js'

function date(date) {
    return DateUtil.format(date, 'dd/MM/yy');
}

function datetime(date) {
    return DateUtil.format(date, 'dd/MM/yy HH:mm');
}

export {
    date,
    datetime
}