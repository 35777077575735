<template>
  <keep-alive>
    <div>
      <div class="d-flex justify-content-center" v-if="loading">
        <b-spinner label="Loading..."></b-spinner>
      </div>
      <div v-show="!loading">
        <div class="grid-video">
          <div>
            <div v-if="!isStartRecording">
              <h2>{{ $t('client.text_27') }}</h2>
              <p>{{ $t('client.text_28') }}</p>
            </div>

            <div v-if="isStartRecording && !isSaveDisabled" data-anima="bottom">
              <h2>{{ $t('client.text_29') }}</h2>
              <p>{{ $t('client.text_30') }}</p>
            </div>

            <div v-if="isStartRecording && isSaveDisabled">
              <div class="top-poses" :class="{ desktop: !isMobile }">
                <div>
                  <p>{{ $t('client.text_31') }}</p>
                  <h2 class="mt-3 mt-1">
                    {{ pose ? $t('client.text_32'): $t('client.text_33') }}
                    {{ getPose(pose) }}
                  </h2>
                </div>

                <div v-if="!isMobile">
                  <img
                    :src="getEmoji"
                    class="emoji"
                    data-anima="right"
                    v-if="pose"
                  />
                  <div class="emoji" v-if="!pose"></div>
                </div>
              </div>

              <div class="container-progress" v-if="pose">
                <b-progress
                  :value="progress_value"
                  :max="Math.floor(timerVideo / poses.length)"
                  class="is-blue"
                ></b-progress>
              </div>
            </div>
          </div>
          <video
            id="myVideo"
            playsinline
            class="video-js vjs-default-skin mt-4"
          ></video>
        </div>

        <div class="actions">
          <BaseButton
            v-if="isSaveDisabled"
            variant="secondary_outline"
            @click="startRecording"
            :disabled="isStartRecording"
            class="btn-ok"
            >{{
              !isStartRecording ? $t('client.text_34') : text_recording
            }}</BaseButton
          >

          <BaseButton
            variant="info3"
            v-if="!isSaveDisabled"
            @click="retakeVideo"
            >{{ $t('client.text_35') }}</BaseButton
          >
          <BaseButton
            variant="secondary_outline"
            v-if="!isSaveDisabled"
            @click="submitVideo"
            >{{ $t('client.text_36') }}
          </BaseButton>
        </div>
        <div v-if="isMobile">
          <b-form-group :label="$t('client.select_the_cam')" label-for="cam" class="mt-4">
            <b-form-select
              id="cam"
              name="cam"
              v-model="deviceSelected"
              @change="changeVideoInput"
              :options="devicesDisponiveis"
              :disabled="isStartRecording"
            ></b-form-select>
          </b-form-group>
        </div>
      </div>
    </div>
  </keep-alive>
</template>

<script>
import { EventBus } from "@/main.js";

import "video.js/dist/video-js.css";
import "videojs-record/dist/css/videojs.record.css";
import videojs from "video.js";
import "webrtc-adapter";
import RecordRTC from "recordrtc";
import Record from "videojs-record/dist/videojs.record.js";
import FFmpegjsEngine from "videojs-record/dist/plugins/videojs.record.ffmpegjs.js";
import { applyVideoWorkaround } from "./browser-workarounds.js";

export default {
  props: {
    poses: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      client: {
        width: 0,
      },
      devicesDisponiveis: [],
      deviceSelected: "",
      loading: false,
      text_recording: "...",
      poses_response: [],
      pose: "",
      player: "",
      audio: null,
      timerVideo: 18,
      progress_value: 1,
      isSaveDisabled: true,
      isStartRecording: false,
      options: {
        controls: true,
        bigPlayButton: false,
        controlBar: {
          fullscreenToggle: false,
          volumePanel: false,
          deviceButton: false,
          recordToggle: false,
          pipToggle: false,
        },
        fluid: true,
        plugins: {
          record: {
            pip: false,
            audio: false,
            video: true,
            maxLength: 18,
            debug: false,
          },
        },
      },
    };
  },
  watch: {
    poses(value) {
      this.poses_response = value;
    },
  },
  computed: {
    isMobile() {
      // return this.client.width <= 768;
      return this.client.width <= 768;
    },
    userId() {
      return this.$store.getters.getProfile.id;
    },
    getEmoji() {
      let r = "";
      if (this.pose) {
        switch (this.pose.toUpperCase()) {
          case "LOOK_LEFT":
          case "TURN_LEFT": {
            r = "emoji_esquerda";
            break;
          }
          case "LOOK_DOWN": {
            r = "emoji_baixo";
            break;
          }
          case "LOOK_UP": {
            r = "emoji_cima";
            break;
          }
          case "LOOK_RIGHT":
          case "TURN_RIGHT": {
            r = "emoji_direita";
            break;
          }
          case "SMILE": {
            r = "emoji_sorrir";
            break;
          }
          case "BLINK": {
            r = "emoji_piscar";
            break;
          }
        }

        return require(`@/assets/img/${r}.png`);
      } else return null;
    },
  },

  methods: {
    handleResize() {
      // padrão
      this.client.width = window.innerWidth;
    },
    getPose(pose) {
      let r = "";
      if (pose) {
        switch (pose.toUpperCase()) {
          case "LOOK_LEFT": {
            r = this.$t('client.text_37');
            break;
          }
          case "LOOK_DOWN": {
            r = this.$t('client.text_38');
            break;
          }
          case "LOOK_UP": {
            r = this.$t('client.text_39');
            break;
          }
          case "LOOK_RIGHT": {
            r = this.$t('client.text_40');
            break;
          }
          case "TURN_LEFT": {
            r = this.$t('client.text_41');
            break;
          }
          case "TURN_RIGHT": {
            r = this.$t('client.text_42');
            break;
          }
          case "SMILE": {
            r = this.$t('client.text_43');
            break;
          }
          case "BLINK": {
            r = this.$t('client.text_44');
            break;
          }

          default: {
            r = pose;
            break;
          }
        }
        return r;
      } else return pose;
    },
    startRecording() {
      this.pose = "";
      this.isStartRecording = true;
      this.countInit();
      setTimeout(() => {
        this.player.record().start();
      }, 4000);
    },
    retakeVideo() {
      this.$emit("update");
      this.isSaveDisabled = true;
      //     this.startRecording();
    },
    progressAnimate() {
      if (this.isSaveDisabled) {
        this.progress_value = 1;
        const timer = setInterval(() => {
          if (
            this.progress_value >=
            Math.floor(this.timerVideo / this.poses_response.length)
          ) {
            clearInterval(timer);
            this.progress_value = 1;
            this.progressAnimate();
          } else {
            this.progress_value++;
          }
        }, 1000);
      }
    },
    initPoses() {
      let i = 1;
      this.pose = this.poses_response[0];
      const delay = Math.floor(this.timerVideo / this.poses_response.length);
      const timer = setInterval(() => {
        if (document.querySelector("#myVideo")) {
          this.audio.play();
          if (i >= this.poses_response.length) {
            clearInterval(timer);
          } else {
            this.pose = this.poses_response[i];
            i++;
          }
        } else {
          clearInterval(timer);
        }
      }, delay * 1000);

      this.progressAnimate();
    },
    countInit() {
      let i = 3;
      let timer = setInterval(() => {
        if (i >= 0) {
          this.text_recording = `${this.$t('client.text_45')}${i}`;
          i--;
        } else {
          clearInterval(timer);
        }
      }, 1000);
    },
    submitVideo() {
      this.player.record().stopDevice();

      this.$emit("confirm", this.player.recordedData);
    },
    changeVideoInput(event) {
      try {
        this.player.record().setVideoInput(event);
      } catch (error) {
        console.log(error);
        this.deviceSelected = this.devicesDisponiveis[0].value;
      }
    },
  },

  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },

  created() {
    //
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    this.poses_response = this.poses;
    // buscando poses
    applyVideoWorkaround();

    this.audio = new Audio(require("@/assets/audios/pose-check.mp3"));
    const player = videojs("myVideo", this.options);
    this.player = player;
    const vueThis = this;

    // Acesso a camera permitido
    player.one("deviceReady", () => {
      EventBus.$emit("videoPermission", false);
      player.record().enumerateDevices();
    });

    player.on("enumerateReady", function() {
      const devices = player.record().devices;
      devices.forEach((device) => {
        if (device.kind === "videoinput") {
          vueThis.devicesDisponiveis.push({
            value: device.deviceId,
            text: device.label,
          });
        }
      });
      vueThis.deviceSelected = vueThis.devicesDisponiveis[0].value;
    });

    // acesso a camera negado
    player.on("deviceError", () => {
      console.log(player.deviceErrorCode);

      let data = {
        negado: true,
        type: "",
      };

      if (
        player.deviceErrorCode
          .toString()
          .includes("Could not start video source")
      ) {
        data.type = "em uso";
      }

      if (player.deviceErrorCode.toString().includes("Permission denied")) {
        data.type = "negado";
      }
      EventBus.$emit("videoPermission", data);
    });

    player.on("error", function(element, error) {
      console.error(error);
    });

    // iniciando gravação
    player.on("startRecord", () => {
      this.text_recording = this.$t('client.text_46');
      this.initPoses();
    });

    // gravação finalizada
    player.on("finishRecord", () => {
      this.isSaveDisabled = false;
    });

    player.record().getDevice();
  },
};
</script>

<style scoped>
p {
  color: #81858e;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.5;
  margin: 0;
}
h2 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 25px;
}
#myVideo {
  border-radius: 5px;
  overflow: hidden;
  width: 100%;
  min-height: 300px;
}
.actions {
  margin: 0 auto;
  margin-top: 40px;
  display: flex;
  gap: 20px;
}
.actions button {
  flex: 1;
}
.pose {
  font-size: 22px;
  color: #2a63ab;
  font-weight: 600;
}
.container-progress {
  margin: 10px 0;
}
.progress {
  background: #f7f7f7;
  height: 7px;
  border-radius: 5px;
}
.btn-ok:disabled {
  opacity: 1;
  color: #2a63ab;
  background: #0023631a;
}
.top-poses {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.top-poses.desktop {
  margin-top: -50px;
}
.emoji {
  width: 180px;
  min-height: 100px;
}
.grid-video {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 30px;
}
@media screen and (max-width: 900px) {
  .grid-video {
    grid-template-columns: 1fr;
    gap: 10px;
  }
  .top-poses.desktop {
    margin-top: 0;
  }
}
</style>
