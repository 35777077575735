import Cookies from 'js-cookie';
import router from '@/router/index'
import SubUsersService from "@/services/resources/SubUsersService";
const serviceSubUsers = SubUsersService.build();


export default {

    userSuccess: (state, resp) => {
        state.user = resp;
        localStorage.user = JSON.stringify(resp)
        Cookies.set('user', JSON.stringify(resp), {
            expires: 365
        });
    },
    userClub: (state, resp) => {
        state.userClub = resp;
        Cookies.set('club_integration', JSON.stringify(resp), {
            expires: 365
        });
    },
    verifyEmail: (state, resp) => {
        state.verifyEmail = resp;
        Cookies.set('verify_email', JSON.stringify(resp), {
            expires: 365
        });
    },
    setPanel: (state, resp) => {
        state.setPanel = resp.id;
        Cookies.set('setPanel', resp.id, {
            expires: 7
        });
        localStorage.setItem('subUser', JSON.stringify(resp));
    },
    resetPanel: (state, resp) => {
        state.setPanel = null;
        Cookies.remove('setPanel');
        localStorage.removeItem("subUser");
    },
    adminUserSuccess: (state, resp) => {
        state.user = resp;
    },

    userLogout: async (state) => {
        const locationadm = await localStorage.locationadm;       
        const user = await JSON.parse(localStorage.user);
        if(await user.is_admin) {
            await serviceSubUsers
            .delete('remove-subusers-erp')
            .then(async (response) => {
                await console.log('remove-subusers-erp',response);
            })
            .catch(async (err) => {
                await reject(err);
            });
        }
        state.user = await null;
        state.permissions = await null;
        state.verify_permissions = await null;
        state.userClub = await null;
        state.verifyEmail = await null
        state.setPanel = await null
        state.access_token = await '';        
        await Cookies.remove('access_token');
        await Cookies.remove("setPanelName");
        await Cookies.remove("setPanelPermission");
        await Cookies.remove('setPanel');
        await Cookies.remove('club_integration');
        await Cookies.remove('user');
        await Cookies.remove('verify_email');
        await Cookies.remove('menuPermissions');
        await localStorage.removeItem("subUser");
        await sessionStorage.clear();
        await localStorage.clear();
        // Obter o primeiro segmento do pathname após o host
        const pathSegments = await window.location.pathname.split('/').filter(segment => segment);
        const firstPathSegment = await pathSegments.length > 0 ? pathSegments[0] : '';

        if(await firstPathSegment !== 'admin-login') {
            localStorage.locationadm = await locationadm;
            	window.location.href = await window.location.protocol + "//" + window.location.host
        }
    },
}