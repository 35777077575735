var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-menu"},[(!_vm.getMenuMobilePermissions.length)?_vm._l((8),function(n,index){return _c('div',{key:index,staticClass:"item load"},[_vm._m(0,true)])}):[_vm._l((_vm.getMenuMobilePermissions),function(menu,i){return [(
            menu.name === 'Commissions' && _vm.ambassadorEnabled && _vm.ambassadorAcceptedTerms
          )?_c('item',{key:i,attrs:{"name":_vm.$t('toggle_side.' + menu.title),"route":`${_vm.$t('route_name.' + menu.name)}`,"icon":menu.name.toLowerCase()}}):_vm._e(),(
            menu.name === 'Commissions' && _vm.ambassadorEnabled && !_vm.ambassadorAcceptedTerms
          )?_c('item',{key:i,attrs:{"name":_vm.$t('toggle_side.' + menu.title),"route":`${_vm.$t('route_name.' + menu.name)}`,"icon":menu.name.toLowerCase()}}):_vm._e(),(
        menu.name !== 'Commissions' &&
        (!_vm.$store.getters.setPanel ||
        (_vm.$store.getters.setPanel))
      )?_c('item',{key:i,attrs:{"name":_vm.$t('toggle_side.' + menu.title),"route":`${_vm.$t('route_name.' + menu.name)}`,"icon":menu.name.toLowerCase()}}):_vm._e()]}),_c('item',{attrs:{"route":"MyAccount","name":"Minha Conta","icon":"user-config"}}),_c('item',{attrs:{"route":"Settings","name":"Configurações","icon":"company-config"}}),_c('div',{staticClass:"Menu-item-logout",on:{"click":function($event){$event.preventDefault();return _vm.logout.apply(null, arguments)}}},[_c('a',{attrs:{"href":"#"}},[_c('img',{attrs:{"src":require("@/assets/img/icons/sign-out.svg")}}),_vm._v(" "+_vm._s(_vm.$t("side.text_1964"))+" ")])])]],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menu-item-loading"},[_c('div',{staticClass:"icone"})])
}]

export { render, staticRenderFns }