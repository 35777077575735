/**
 * Function for copy text
 *
 * @param {string} text text to be copies
 * @param {(text: string) =>} callback call after copied text
 */
export default async function copy(text, callback = (text) => {}) {
  try {
    await navigator.clipboard.writeText(text);
  } catch (err) {
    const el = document.createElement('textarea');
    el.value = text;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  callback(text);
}
