<template>

  <b-modal id="modal_call_evaluation"
           size="lg"
           centered
           :title="$t('views.call_evaluation.text_941')"
           hide-footer
           no-close-on-backdrop
           @close="closeEvaluation"
  >
    <main class="main-avaliacao">
      <div class="info-main">
        <!-- greenn -->
        <div class="avaliacao-greenn">
          <p class="mb-3">
            {{ $t('views.call_evaluation.text_942') }}
          </p>
          <h2>
            {{ $t('views.call_evaluation.text_944') }}
          </h2>
          <p>{{ $t('views.call_evaluation.text_943') }} <strong>{{operator}}</strong></p>
          <div class="avaliacoes">
            <div
              class="avaliacao-item item-support"
              @click="evaluation('support', 5)"
            >
              <p>😍</p>
              <span>{{ $t('views.call_evaluation.text_945') }}</span>
            </div>
            <div
              class="avaliacao-item item-support"
              @click="evaluation('support', 4)"
            >
              <p>😃</p>
              <span>{{ $t('views.call_evaluation.text_946') }}</span>
            </div>
            <div
              class="avaliacao-item item-support"
              @click="evaluation('support', 3)"
            >
              <p>😐</p>
              <span>{{ $t('views.call_evaluation.text_947') }}</span>
            </div>
            <div
              class="avaliacao-item item-support"
              @click="evaluation('support', 2)"
            >
              <p>🙁</p>
              <span>{{ $t('views.call_evaluation.text_948') }}</span>
            </div>
            <div
              class="avaliacao-item item-support"
              @click="evaluation('support', 1)"
            >
              <p>😞</p>
              <span>{{ $t('views.call_evaluation.text_949') }}</span>
            </div>
          </div>
        </div>
      </div>
    </main>
    <main class="main-avaliacao mt-0">
      <div class="info-main">
        <!-- greenn -->
        <div class="avaliacao-greenn">
          <h2>
            Voomp
          </h2>
          <p>{{ $t('views.call_evaluation.text_950') }}</p>
          <div class="avaliacoes">
            <div
              class="avaliacao-item item-greenn"
              @click="evaluation('greenn', 5)"
            >
              <p>😍</p>
              <span>{{ $t('views.call_evaluation.text_945') }}</span>
            </div>
            <div
              class="avaliacao-item item-greenn"
              @click="evaluation('greenn', 4)"
            >
              <p>😃</p>
              <span>{{ $t('views.call_evaluation.text_946') }}</span>
            </div>
            <div
              class="avaliacao-item item-greenn"
              @click="evaluation('greenn', 3)"
            >
              <p>😐</p>
              <span>{{ $t('views.call_evaluation.text_947') }}</span>
            </div>
            <div
              class="avaliacao-item item-greenn"
              @click="evaluation('greenn', 2)"
            >
              <p>🙁</p>
              <span>{{ $t('views.call_evaluation.text_948') }}</span>
            </div>
            <div
              class="avaliacao-item item-greenn"
              @click="evaluation('greenn', 1)"
            >
              <p>😞</p>
              <span>{{ $t('views.call_evaluation.text_948') }}</span>
            </div>
          </div>
          <p>
            {{ $t('views.call_evaluation.text_951') }}
          </p>
          <b-form-group label="" label-for="text_greenn" class="mt-4">
            <textarea
                id="text_product"
                name="text_greenn"
                :placeholder="$t('views.call_evaluation.text_1552')"
                v-model="message"
                maxlength="255"
                cols="30"
                rows="10"
            ></textarea>
          </b-form-group>

          <div data-anima="bottom" class="mt-4">
            <BaseButton
                variant="black"
                class="btn-avaliacao"
                @click="onSubmit"
            >{{ $t('views.call_evaluation.text_952') }}</BaseButton>
          </div>
        </div>
      </div>
    </main>
    <template v-slot:footer="{ cancel }">
      <div></div>
    </template>
  </b-modal>
</template>

<script>
import Vue from "vue";
import callEvaluationService from "@/services/resources/CallEvaluationService";
const callEvaluation = callEvaluationService.build();
// import crisp from '@/mixins/crisp.js'

export default {
  name: "CallEvaluation",

  data() {
    return {
      evaluation_greenn: null,
      evaluation_support: null,
      message: null,
      session_id: null,
      operator: null,
      sent_evaluation: false,
    };
  },
  // mixins: [crisp],
  methods: {
    closeEvaluation() {
      Vue.swal({
        title: this.$t('views.call_evaluation.text_953'),
        text: this.$t('views.call_evaluation.text_954'),
        type: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t('views.call_evaluation.text_1553'),
        cancelButtonText: this.$t('views.call_evaluation.text_1554'),
        customClass: "sweet-container",
        confirmButtonClass: "button button-black mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
        allowOutsideClick: false,
      }).then((result) => {
        if (!result.isConfirmed) {
          this.$bvModal.show("modal_call_evaluation");
        } else {
          this.onSubmit("outside");
        }
      });
    },
    evaluation(type, value) {
      switch (type) {
        case "greenn":
          this.evaluation_greenn = value;
          break;
        case "support":
          this.evaluation_support = value;
          break;
      }
      let avaliacoes = [
        ...document.querySelectorAll(".item-" + type),
      ].reverse();
      avaliacoes.forEach((item) => {
        item.classList.remove("ativo");
      });
      avaliacoes[value - 1].classList.add("ativo");
    },
    onSubmit(onClink) {
      this.sent_evaluation = true;
      let data = {
        session_id: this.getSessionCrisp(),
        is_evaluation: true,
        website_id: process.env.CRISP_WEBSITE_ID,
      };

      if (onClink === "outside") {
        data.evaluation_to_system = null;
        data.evaluation_to_support = null;
        data.message = null;

      }else{

        if(!this.evaluation_support){
          this.$bvToast.toast(this.$t('views.call_evaluation.text_955'), {
            variant: "info",
            title: this.$t('views.call_evaluation.text_1555'),
            autoHideDelay: 8000,
            appendToast: true,
          });
          return false;
        }

        data.evaluation_to_system = this.evaluation_greenn;
        data.evaluation_to_support = this.evaluation_support;
        data.message = this.message;
      }
      callEvaluation.post('', data).then(resp=>{
        if(resp.id){
          this.$bvToast.toast(this.$t('views.call_evaluation.text_956'), {
            variant: "success",
            title: this.$t('views.call_evaluation.text_1555'),
            autoHideDelay: 8000,
            appendToast: true,
          });
          window.$crisp.push(["do", "session:reset"]);

          this.crispWitUser(true);

          this.$bvModal.hide("modal_call_evaluation");
          this.evaluation_greenn = null;
          this.evaluation_support = null;
          this.message = null;
          this.operador = null;
        }
      });
    },
    async check(interval_check) {
      let data = await callEvaluation.search({
        session_id: this.getSessionCrisp(),
      });

      if (data.resolved) {
        this.operator = data.operator;
        this.$bvModal.show("modal_call_evaluation");
        clearInterval(interval_check);
      }
    },
    async checkForEvaluation() {
      let interval_check = undefined;
      await this.checkEvaluation(false, true);

      if (window.$crisp) {
        const dados_user = this.$store.getters.getProfile || localStorage.user;

        window.$crisp.push([
          "on",
          "message:sent",
          () => {
            callEvaluation.post("", {
              session_id: $crisp.get("session:identifier"),
              user_id: dados_user.id,
              website_id: process.env.CRISP_WEBSITE_ID,
            });

            interval_check = setInterval(async () => {
              await this.check(interval_check);
            }, 30000);

            $crisp.push(["off", "message:sent"]);
          },
        ]);
      }

      //Verifica se tem alguma avaliação para ser feita
      await this.check(interval_check);
    },
  },
  mounted() {
    this.checkForEvaluation();
  },
};
</script>

<style scoped>
/*  main*/
.main-avaliacao {
  display: grid;
}

@media screen and (max-width: 667px) {
  .main-avaliacao {
    margin-top: 20px;
    display: flex;
    flex-direction: column-reverse;
  }
}

.info-main {
  padding: 0 30px;
}

.info-main h1 {
  color: #020016;
  font-size: 52px;
  font-weight: bold;
  line-height: 1.1;
  margin-bottom: 20px;
}
.info-main p {
  margin: 0;
  font-size: 18px;
  color: #81858e;
  line-height: 1.6;
  width: 100%;
}
.info-main p + p {
  margin-top: 10px;
}
@media screen and (max-width: 667px) {
  .info-main h1 {
    font-size: 32px;
  }
  .info-main p {
    margin: 10px 0 20px 0;
    font-size: 15px;
  }
}
.info-main span {
  font-size: 14px;
  font-weight: 600;
  color: #020016;
  display: block;
  margin-bottom: 10px;
  text-align: center;
}
.info-main .btn-avaliacao {
  width: 365px;
  height: 60px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
@media screen and (max-width: 667px) {
  .info-main .btn-avaliacao {
    width: auto;
    padding: 0 40px !important;
  }
}

@keyframes animaArrow {
  0%,
  40% {
    transform: translate3d(0, 0, 0);
  }
  70% {
    transform: translateY(7px);
  }
}

/* novos */
#text_product {
  height: 15vh !important;
  width: 100%;
  resize: none;
  color: #020016;
  border: 1px solid #c1c7d4 !important;
}
#text_product:hover,
#text_product:focus {
  border-color: #37cf79 !important;
}
@media screen and (max-width: 667px) {
  #text_product,
  .avaliacoes {
    width: 100%;
  }
}

/* greenn */
.avaliacao-greenn {
  margin-top: 10px;
}
.avaliacao-greenn h2 {
  font-weight: 800;
  font-size: 20px;
  color: #020016;
  display: flex;
  align-items: center;
  gap: 7px;
}
.avaliacao-greenn h2 h3 {
  font-weight: normal;
  font-size: 14px;
  color: #81858e;
  margin: 0;
}
.avaliacao-greenn p {
  font-size: 16px;
}
.avaliacoes {
  margin: 30px 0;
  /*padding: 0 30px;*/
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.avaliacao-item {
  /*display: inline-block;*/
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  cursor: pointer;
  transition: 0.3s;
  padding: 10px;
}

@media screen and (max-width: 470px) {
  .avaliacao-item {
    display: inline-block;
  }
  .avaliacoes {
    display: block;
  }
}

.avaliacao-item:hover p,
.avaliacao-item.ativo p {
  transform: scale(1.1);
  background: #37cf79;
  border: none;
}
.avaliacao-item p {
  width: 55px;
  height: 55px;
  background: #f7f7f7;
  border: 1px solid #ededf0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: 0.3s;
}
.avaliacao-item span {
  color: #020016;
}

@media screen and (max-width: 667px) {
  .detalhes-produto {
    grid-template-columns: 50px 1fr;
    position: initial;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 667px) {
  .img-produto {
    max-width: 50px;
    width: 50px;
    height: 50px;
  }
}
</style>
