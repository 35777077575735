import getters from '@/store/modules/reports/getters';
import mutations from '@/store/modules/reports/mutations'
import moment from 'moment-timezone';

 
const state = {
    filter: {
        startDate: moment().tz("America/Sao_Paulo").subtract(7, 'days').format("YYYY-MM-DDT00:00:00-03:00"),
        endDate: moment().tz("America/Sao_Paulo").format("YYYY-MM-DDT23:59:59-03:00")
    },

}


export {
    state,
    getters,
    mutations,
}