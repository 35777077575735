<template>
  <b-modal :id="name" :size="size" :title="title" :centered="centered" @shown="shown" @hidden="hidden" v-bind="$attrs">
    <template v-slot:modal-header="{ close }">
      <slot name="header" :close="{ close }"></slot>
    </template>
    
    <slot></slot>

    <template v-slot:modal-footer="{ cancel }">
      <slot name="footer" :cancel="cancel"> </slot>
    </template>
  </b-modal>
</template>
<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: "lg",
    },
    title: {
      type: String,
      required: true,
    },
    centered: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    shown() {
      this.$emit("shown", true);
      document.querySelector("body").style = "overflow: hidden;";
    },
    hidden() {
      this.$emit("hidden");
      document.querySelector("body").style = "overflow: auto !important; ";
    },
    resetUrl() {
      if (Object.values(this.$route.query).length) {
        this.$router.push({ query: {} });
      }
    }
  },
};
</script>

<style>
.modal {
  overflow: auto;
}

.modal-content {
  padding: 20px 30px;
  animation: showTop 0.4s forwards;
}

.modal-content .modal-header {
  border-bottom: 1px solid #ededf0;
  border-radius: 0;
  padding-bottom: 25px;
  padding-left: 0;
}

.modal-content .modal-header h5 {
  font-size: 16px;
  font-weight: 600;
}

.modal-body {
  padding: 20px 0;
}

.col-auto {
  padding: 0;
}

.modal-content .modal-header .close {
  background: url("../assets/img/icons/close.svg") no-repeat center center;
  overflow: hidden;
  text-indent: -100px;
  color: transparent;
  outline: none;
  border: none;
}

.modal-content .modal-footer {
  border-top: 1px solid #ededf0;
  border-radius: 0;
  padding-top: 25px;
}

/* modal 100% */
#product-edit {
  padding: 0 !important;
}

#product-edit .modal-xl {
  margin: 0 auto;
  max-width: 100vw;
}

@media (min-width: 1600px) {
  #product-edit .modal-xl {
    max-width: 1300px;
  }
}

#product-edit .modal-content {
  border-radius: 0 !important;
}

#modal-info-aprovacao footer {
  display: none !important;
}
</style>