<template>
  <BaseModal name="modal-voompplay" size="md" title="" @shown="onOpenModal" @hidden="onCloseModal" centered
    :hide-footer="loading || hasPlays" hide-header>

    <section class="content">
      <section class="header">
        <section class="title d-flex flex-column">
          <h5>{{ $t('seller.voomp_play.text_104') }}</h5>
          <div v-if="!loading && hasPlays" class="note">
            <p>{{ $t('seller.voomp_play.text_100') }}</p>
          </div>
          <div v-if="!loading && !hasPlays && hasProducts" class="note">
            <p>{{ $t('seller.voomp_play.text_101') }}</p>
            <p>{{ $t('seller.voomp_play.text_102') }}</p>
          </div>
        </section>
        <div class="close" @click="handleModalHide()">x</div>
      </section>

      <div class="d-flex justify-content-center align-items-center" v-if="loading">
        <b-spinner label="Loading..."></b-spinner>
      </div>

      <div v-if="!loading" class="d-flex flex-column" style="gap: 24px;">
        <div v-if="!hasPlays" class="d-flex flex-column" style="gap: 24px;">
          <div v-if="!hasProducts" class="note-warning">
            <img src="@/assets/img/icons/warning.svg" alt="">
            <p>
              {{ $t('seller.voomp_play.text_103') }}
            </p>
          </div>

          <section class="helper-center">
            <p>{{ $t('seller.voomp_play.text_105') }}</p>
            <div class="d-flex flex-column">
              <a v-if="!hasProducts" target="_blank"
                href="https://faq.voompcreators.com.br/cadastro-de-produtos/como-cadastrar-o-meu-produto/">
                {{ $t('seller.voomp_play.text_106') }}
              </a>
              <a v-if="hasProducts && !hasPlays" target="_blank"
                href="https://faq.voompcreators.com.br/conheca-a-voomp-play/como-criar-a-sua-area-de-membros-na-voomp-play/">
                {{ $t('seller.voomp_play.text_107') }}
              </a>
              <a v-if="hasProducts && !hasPlays" target="_blank"
                href="https://faq.voompcreators.com.br/integracao-de-produtos/como-integrar-a-voomp-creators-com-a-voomp-play/">
                {{ $t('seller.voomp_play.text_108') }}
              </a>
            </div>
          </section>
        </div>

        <div v-if="hasPlays" v-for="play in plays" class="vompplay-group">
          <BaseInput name="form-input" :label="`#${play.id} - ${play.name}`" tooltip
            :value="buildVoompPlayUrl(play.subdomain)" class="vompplay-input-link" disabled copyable>
          </BaseInput>
          <b-button class="vompplay-button primary link" @click="handleLoginClub(play.subdomain)">
            {{ $t('seller.voomp_play.text_112') }}
          </b-button>
        </div>
      </div>
    </section>

    <template v-slot:footer="{ cancel }">
      <div class="d-flex" style="gap: 12px; margin: 0;">
        <b-button type="button" class="vompplay-button secondary" @click="cancel">{{
          $t('seller.voomp_play.text_111') }}</b-button>
        <b-button v-if="!hasProducts" type="button" class="vompplay-button primary" @click="handleGoTo('/produtos')">
          {{ $t('seller.voomp_play.text_109') }}
        </b-button>
        <b-button v-if="hasProducts && !hasPlays" type="button" class="vompplay-button primary"
          @click="handleGoTo('/produtos')">
          {{ $t('seller.voomp_play.text_110') }}
        </b-button>
      </div>
    </template>
  </BaseModal>
</template>

<script>
export default {
  name: 'VoompPlay',
  data() {
    return {
      plays: [],
      loading: true,
      hasProducts: false,
    }
  },
  computed: {
    hasPlays() {
      return !!this.plays.length;
    }
  },
  async mounted() {
    const { default: IntegrationService } = await import('@/services/resources/IntegrationService');
    const { default: ProductService } = await import('@/services/resources/ProductService');
    const { default: ClubService } = await import('@/services/resources/ClubService');

    this.integration = IntegrationService.build();
    this.products = ProductService.build();
    this.club = ClubService.build();
  },
  methods: {
    redirect(link) {
      window.open(link, '_blank');
    },
    async fetchResources() {
      try {
        this.loading = true;

        const products = await this.products.search({ page: 1, per_page: 1 });

        if (products.total === 0) {
          this.hasProducts = false;
          return;
        }
        this.hasProducts = true;

        this.plays = await this.integration.search({ url: '/list/club' });

      } catch (error) {
        this.plays = [];
        this.hasProducts = false;
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    removeSubdomain(host) {
      const url = new URL(host);
      const parts = url.hostname.split('.');

      if (parts.length > 2) {
        parts.shift();
      }
      url.hostname = parts.join('.');
      return url.toString();
    },
    buildVoompPlayUrl(subdomain) {
      const url = new URL(this.removeSubdomain(process.env.VUE_CLUB_API_HOST));

      return `${url.protocol}//${subdomain}.${url.host}`;
    },
    onOpenModal() {
      this.fetchResources();
    },
    onCloseModal() {
      this.plays = [];
      this.loading = true;
      this.hasProducts = false;
    },
    handleGoTo(path) {
      this.$router.push(path);
      this.$bvModal.hide('modal-voompplay');
    },
    handleModalHide() {
      this.$bvModal.hide('modal-voompplay');
    },
    async handleLoginClub(subdomain) {
      try {
        this.$bvToast.toast(this.$t('seller.voomp_play.text_113'), {
          variant: 'info',
          title: this.$t('seller.voomp_play.text_114'),
          autoHideDelay: 2000,
          appendToast: true,
        });

        const { string } = await this.club.search({ url: '/login', subdomain });
        const url = `${this.buildVoompPlayUrl(subdomain)}/?rdr=${string}`;

        window.open(url, "_blank");
      } finally { }
    }
  },
};
</script>

<style>
/* Modal Container Styles */
#modal-voompplay .modal-dialog.modal-md {
  max-width: 635px !important;
  font-family: var(--Default-font-family);
}

#modal-voompplay .modal-content {
  max-height: 387px !important;
  padding: 24px !important;
  overflow-y: auto;
  border-radius: 8px;
  gap: 48px;
}

/* Section: Content */
#modal-voompplay section.content {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
}

/* Section: Header */
#modal-voompplay section.header {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
}

#modal-voompplay section.header section.title {
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 1;
}

#modal-voompplay section.header section.title h5 {
  color: var(--Default-color-gray-900);
  font-size: 18px;
  font-weight: var(--Default-font-weight-semibold);
  line-height: 28px;
  margin: 0;
}

/* Close Button */
#modal-voompplay section.header .close {
  background: url("../../../../assets/img/icons/close.svg") no-repeat center;
  color: transparent;
  border: none;
  cursor: pointer;
  width: 16px;
  height: 16px;
  padding: 0 !important;
  margin: 0 !important;
  text-indent: -100px;
  overflow: hidden;
  opacity: 1;
}

/* Modal Body */
#modal-voompplay .modal-body {
  display: flex;
  flex-direction: column;
  padding: 0 !important;
  gap: 24px;
}

/* Text Styles */
#modal-voompplay .note p,
#modal-voompplay .helper-center p,
#modal-voompplay .helper-center a {
  font-family: var(--Default-font-family);
  font-size: var(--Default-font-size-base);
  font-weight: var(--Default-font-weight-regular);
  line-height: var(--Default-line-height-base);
  margin: 0;
}

#modal-voompplay .helper-center p {
  color: var(--Default-color-gray-600);
}

#modal-voompplay .helper-center a {
  color: var(--Default-color-primary-800);
}

#modal-voompplay .note p {
  color: var(--Default-color-gray-500);
}

/* Footer Styles */
#modal-voompplay .modal-footer {
  border-top: 0 !important;
  padding: 0 !important;
}

/* Input Link Legend */
#modal-voompplay .vompplay-input-link legend {
  width: 456px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Button Group */
#modal-voompplay .vompplay-group {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 12px;
  width: 100%;
}

/* Button Styles */
#modal-voompplay .vompplay-button {
  font-size: var(--Default-font-size-base);
  font-weight: var(--Default-font-weight-semibold);
  line-height: var(--Default-line-height-base);
  font-family: var(--Default-font-family);
  border-radius: 4px;
  padding: var(--Default-button-padding);
}

#modal-voompplay .vompplay-button.primary {
  background: var(--Default-color-primary-800);
  border-color: var(--Default-color-primary-800);
}

#modal-voompplay .vompplay-button.primary.link {
  height: 38px;
  padding: 0 10px;
}

#modal-voompplay .vompplay-button.secondary {
  background: transparent;
  color: var(--Default-color-gray-900);
  border: 1px solid var(--Default-color-gray-300);
}

/* Warning Note */
#modal-voompplay .note-warning {
  display: flex;
  align-items: flex-start;
  border: 1px solid var(--Default-color-yellow-400);
  background-color: var(--Default-color-yellow-50);
  border-radius: 6px;
  padding: 16px;
  gap: 12px;
  width: 100%;
}

#modal-voompplay .note-warning p {
  color: var(--Default-color-yellow-700);
  font-family: var(--Default-font-family);
  font-size: var(--Default-font-size-base);
  font-weight: var(--Default-font-weight-regular);
  line-height: var(--Default-line-height-base);
  margin: 0;
}
</style>
