const Utils = {
    computed: {
        userProfile() {
            if (localStorage.getItem("subUser")) {
                let subUser = JSON.parse(localStorage.getItem("subUser"));
                return subUser;
            } else {
                return this.$store.getters.getProfile;
            }
        },        
        userID() {
            if (localStorage.getItem("subUser")) {
                let subUser = JSON.parse(localStorage.getItem("subUser"));
                return subUser.id;
            } else {
                return this.$store.getters.getProfile.id;
            }
        },
        username() {
            if (localStorage.getItem("subUser")) {
                let subUser = JSON.parse(localStorage.getItem("subUser"));
                return subUser.username;
            } else {
                return this.$store.getters.getProfile.username;
            }
        },
        isSeller() {
            if (localStorage.getItem("subUser")) {
                let subUser = JSON.parse(localStorage.getItem("subUser"));
                let isSeller = false;
                subUser.roles.forEach(function(role){
                        if(role.name === 'Seller'){
                            isSeller = true;
                            return;
                        }
                });
                return isSeller;
            } else {
                return this.$store.getters.isSeller;
            }
        },
        ambassadorEnabled() {
            if (localStorage.getItem("subUser")) {
                let subUser = JSON.parse(localStorage.getItem("subUser"));
                return subUser.ambassador_enabled;
            } else {
                return this.$store.getters.getProfile.ambassador_enabled;
            }
        },
        ambassadorAcceptedTerms() {
            if (localStorage.getItem("subUser")) {
                let subUser = JSON.parse(localStorage.getItem("subUser"));
                return subUser.ambassador_accepted_terms;
            } else {
                return this.$store.getters.getProfile.ambassador_accepted_terms;
            }
        },        
    },
    methods: {
        formatTelWhats(tel) {
            if (tel) {
                if (tel.toString().indexOf("+55") > -1) {
                    var tell = tel.replace(/[() -]/g, "");
                    if (tell[0] === "+") {
                        tell = tell.substring(1);
                    }
                    return `https://api.whatsapp.com/send?phone=${tell}`;
                } else {
                    return `https://api.whatsapp.com/send?phone=55${tel.replace(
                    /[() -]/g,
                    ""
                    )}`;
                }
            }
        },
        percent(num, dec = 2) {
            return Number(num/100).toLocaleString('pt-BR',{style: 'percent', minimumFractionDigits:dec}); 
        },
    }
}

export default Utils