<template>
  <keep-alive>
    <div>
      <div>
        <h2>{{ $t('client.presentation_video') }}</h2>
        <p>
          {{ $t('client.text_22') }}
          <a href="https://youtube.com/shorts/pqwzVCuGOpU?feature=share" target="_blank">{{ $t('client.text_23') }}</a>,
          {{ $t('client.text_24') }}
        </p>
        <b-form-file
          class="mt-3"
          id="video_upload"
          name="video_upload"
          plain
          v-model="video_upload"
          accept="video/*"
          @change="changeFile"
        ></b-form-file>
        <p class="text-center">{{ $t('client.text_25') }}</p>
      </div>
      <div class="actions mt-3">
        <BaseButton variant="info2" @click="onSubmit" :disabled="!video_upload"
          >{{ $t('client.text_26') }}</BaseButton
        >
      </div>
    </div>
  </keep-alive>
</template>

<script>

export default {
  data() {
    return {
      video_upload: null,
    };
  },

  methods: {
    changeFile(e) {
      this.video_upload = e.target.files[0];
    },
    onSubmit() {
      this.$emit("confirm", this.video_upload);
    },
  },
};
</script>

<style scoped>
p {
  color: #81858e;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.5;
  margin: 0;
}
h2 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 25px;
}

.actions {
  margin: 0 auto;
  margin-top: 40px;
  display: flex;
  gap: 20px;
}
.actions button {
  flex: 1;
}
</style>
