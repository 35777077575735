<template>
  <div class="Menu wrapper" :class="{ ativo: !menu_mobile }">
    <div class="Menu logo-close">
      <div class="Menu-logo mb-4">
        <router-link to="/" class="after-element"
          ><img src="@/assets/logo.png" class="logo"
        /></router-link>

        <div class="btn-menu" @click="openMenu">
          {{ menu_mobile ? "Menu" : "Fechar" }}
          <img
            src="@/assets/img/icons/menu.svg"
            v-show="menu_mobile"
            data-anima="mobile"
          />
          <img
            src="@/assets/img/icons/close.svg"
            v-show="!menu_mobile"
            data-anima="mobile"
          />
        </div>
      </div>

      <Menu v-if="!menu_mobile" data-anima="top" />
    </div>
  </div>
</template>
<script>
// import Menu from "./Menu";
import Menu from "./Menu";

export default {
  components: {
    Menu,
  },
  data() {
    return {
      menu_mobile: true,
      salesActive: false,
    };
  },
  methods: {
    openMenu() {
      this.menu_mobile = !this.menu_mobile;
      document.body.style.overflow =
        document.body.style.overflow == "hidden" ? "auto" : "hidden";
    },
    logout() {
      this.$store.dispatch("logoutRequest").catch(function(err) {});
    },
    maximizeSidebar() {
      this.$store.commit("changeSidebarState");
    },
  },
};
</script>

<style lang="scss" scoped>
.Menu {
  height: 70px;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99999;
  padding: 5px 15px;
  border-right: none;
  border-left: none;
  transition: 0.3s;

  .Menu-logo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 5px;
    img {
      width: 90px;
      height: auto;
    }
  }

  .Menu-collapse {
    text-align: center;
    margin-bottom: 10px;
    color: $base-color;
    cursor: pointer;
    padding: 10px;
  }
}
.Menu.ativo {
  height: 100% !important;
}
.btn-menu {
  padding: 15px;
  padding-right: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
}
.btn-menu img {
  margin-left: 10px;
  transition: 0.3s;
  width: auto !important;
}
</style>
