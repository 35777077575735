<template>
  <div>
    <slot name="button" :openModal="openModal"> </slot>
      <BaseModal :name="`confirm-dialog-${id}`" size="md" title="Confirmar Ação">
        <b-row>
          <b-col cols="12">
            Tem certeza que deseja continuar?
          </b-col>
        </b-row>
      </BaseModal>
    </slot>
  <BaseModal :name="`confirm-dialog-${id}`" size="md" title="Confirmar Ação">

    <b-row>
      <b-col cols="12">
        {{ $t('text_1') }}
      </b-col>
    </b-row>

    <template v-slot:footer="{ cancel }">
      <BaseButton 
      class="mr-4"
        variant="link-info" 
        @click="cancel">
        {{ $t('client.text_51') }}
      </BaseButton>
      <BaseButton 
        variant="primary" 
        @click="onSubmit">
        {{ $t('client.text_19') }}
      </BaseButton>
    </template>

  </BaseModal>
</div>
</template>
<script>
export default {
  data() {
    return {
      id: parseInt(Math.random() * 1000),
    };
  },
  methods: {
    openModal() {
      this.$bvModal.show(`confirm-dialog-${this.id}`);
    },
    onSubmit() {
      this.$emit("confirmed");
      this.$bvModal.hide(`confirm-dialog-${this.id}`);
    },
  },
};
</script>
