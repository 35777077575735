<template>
  <b-form-group v-b-tooltip.hover :title="tooltip ? label : null" :label="label" :label-for="id"
    class="base-input-formgroup">
    <b-form-input :id="id" :state="isValid" v-bind="$attrs" v-model="inputValue" @input="$emit('input', inputValue)"
      :class="['base-input', ...classes]" :style="{
        height: '38px !important',
        padding: '0px 12px !important',
        border: '1px solid var(--Default-gray-300, #D1D5DB)',
      }"></b-form-input>
    <b-form-invalid-feedback v-if="!isValid && errorMessage">
      {{ errorMessage }}
    </b-form-invalid-feedback>
    <img v-if="hasIcon" class="base-input-icon" :src="require(`@/assets/img/icons/${copyable ? 'copy' : icon}.svg`)"
      @click="copyValue()" />
  </b-form-group>
</template>

<script>
import copy from '@/utils/copy';

export default {
  name: 'BaseInput',
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: '',
    },
    id: {
      type: String,
    },
    value: {
      type: [String, Number],
      default: '',
    },
    errorMessage: {
      type: String,
      default: '',
    },
    isValid: {
      type: Boolean,
      default: null,
    },
    icon: {
      type: String,
    },
    copyable: {
      type: Boolean,
    },
    tooltip: {
      type: Boolean,
    }
  },
  data() {
    return {
      inputValue: this.value,
    };
  },
  computed: {
    classes() {
      let classes = [];
      return classes;
    },
    hasIcon() {
      if (this.copyable) {
        return true;
      }

      if (this.icon) {
        return true;
      }
      return false;
    },
  },
  methods: {
    copyValue() {
      if (this.copyable) {
        copy(this.inputValue, () => {
          this.$bvToast.toast(this.$t('shared.text_2047'), {
            variant: 'success',
            title: this.$t('shared.text_2048'),
            autoHideDelay: 5000,
            appendToast: true,
          });
        });
      }
    },
  },
  watch: {
    value(newValue) {
      this.inputValue = newValue;
    },
  },
};
</script>
<style lang="scss">
.base-input-formgroup {
  position: relative;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
}

.base-input-formgroup legend {
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 20px;
  color: var(--Default-gray-900, #111827);
  margin-bottom: 6px !important;
}

.base-input {
  border: 1px solid var(--Default-gray-300, #d1d5db);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  height: 38px !important;
  padding: 0px 12px;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  &:disabled {
    background: var(--Default-gray-50, #f9fafb) !important;
    color: #6b7280 !important;
    cursor: not-allowed;
  }
}

.base-input-icon {
  position: absolute;
  right: 10px;
  top: 11px;
  width: 16px;
  height: 16px;
  cursor: pointer;
  filter: invert(50%);
  cursor: pointer;
  transition: 0.3s ease;
}
</style>
